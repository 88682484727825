import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/c_social/c_social/src/app/scroll-to-top.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/page/home.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/page/not-found.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/c_social/c_social/src/page/profile.tsx");
